import React, { useState, useEffect } from 'react';
import GridItem from './GridItem';
import './ImageGrid.css';

function ImageGrid() {

    const [isMobile, setIsMobile] = useState('')
    const [gridStyles, setButtom] = useState({
        paddingLeft: '290px',
        'height': '95vh',
        marginTop: '0px',
        minWidth: '517px'
    });

    const setGridStyles2 = () => {
        if (window.innerWidth <= 960) {
            setButtom({
                paddingLeft: '0px',
                'height': '349px',
                marginTop: '60px',
                minWidth: '517px'
            });
            setIsMobile('-mobile')
        } else {
            setButtom({
                paddingLeft: '290px',
                'height': '95vh',
                marginTop: '0px',
                minWidth: '517px'
            });
            setIsMobile('')
        }
    };

    useEffect(() => {
        window.addEventListener('resize', setGridStyles2);
        setGridStyles2();
    }, []);
    return (
        <>
            <div className={`assets-grid-wrapper${isMobile}`}>
                <div className={`assets-grid${isMobile}`} style={gridStyles}>
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Javiera3742_web.jpg"
                        imgAlt="Javiera, retrato en blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='0'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Dani-S1A6959SQ.jpg"
                        imgAlt="Dani, retrato en blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='1'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Jimena-1.jpg"
                        imgAlt="Jimena en body, blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='2'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Laura-FILM1.jpg"
                        imgAlt="Laura en body blanco, retrato a color en film"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='3'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Delfina_S1A2561_WEB.jpg"
                        imgAlt="Delfina, retrato en blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='4'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Maca-9565.jpg"
                        imgAlt="Macarena, para Kinibe"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='5'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Melina-S1A3231.jpg"
                        imgAlt="Melina, retrato en blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='6'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Fernanda_5590BW_WEB.jpg"
                        imgAlt="Fernanda, retrato blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='7'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Sofia-S1A0296.jpg"
                        imgAlt="Jimena en body, blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='8'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Tania-3629.jpg"
                        imgAlt="Tania, blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='9'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Oriana_S1A00069_WEB.jpg"
                        imgAlt="Oriana, blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='10'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa_Nathalia_S1A9220SQ_WEB.jpg"
                        imgAlt="Jimena en body, blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='11'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Ana-01158_WEB.jpg"
                        imgAlt="Ana, blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='12'
                    />
                    <GridItem
                        imgSrc="images/sessions/Franco_Canepa-Margot_S1A3628_WEB.jpg"
                        imgAlt="Margot, retrato en blanco y negro"
                        imgClass={`gallery-element${isMobile}`}
                        imgIndex='13'
                    />
                    
                </div>
            </div>
        </>
    )
}

export default ImageGrid
