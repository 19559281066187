import React from 'react';
import '../../App.css';



function Contacts() {

  return (
    <>
      <div className="contact-page-wrapper">
        <p className="mail-link"><a href="mailto:franco.canepa@gmail.com" data-link-type="mailto">franco.canepa@gmail.com</a></p>
        <p className="insta-link"><a href="http://instagram.com/franco.canepa" target='_blank' rel='noreferrer' >instagram/franco.canepa</a></p>
      </div>
    </>
  );
}

export default Contacts;