import React from 'react';
import './GridItem.css';

function GridItem({ imgSrc,
    imgAlt,
    imgTitle,
    imgClass,
    imgIndex }) {
    return (
        <>
            <div className="asset image" data-asset-index={imgIndex}>
                <div className="image-wrap">
                    <div className="image-wrap-inner">
                        <img className={imgClass} alt={imgAlt} src={imgSrc} loading="lazy" title={imgTitle}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GridItem
