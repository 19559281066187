import React from 'react';
import '../../App.css';
import ImageGrid from '../ImageGrid';

function Home() {

  return (
    <>
      <main className="container show-container" >
          <ImageGrid/>
      </main>
    </>
  );
}

export default Home;