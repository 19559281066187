import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MenuBar from './components/MenuBar';
import Contacts from './components/pages/Contact';



function App() {
  return (
    <>
      <Router>
        <MenuBar/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contacts/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;