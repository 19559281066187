import React, { useState, useEffect } from 'react'
import './MenuBar.css';
import { Link } from 'react-router-dom';

function MenuBar() {
  const [claseMenu, setButton] = useState('');
  const [clickMenu, setMenuActive] = useState(false);

  const closeMobileMenu = () => setMenuActive(false);

  const handleClick = () => {
    setMenuActive(!clickMenu);
  }

  const setMenuClass = () => {
    if (window.innerWidth <= 960) {
      setButton('mobile-menu');
    } else {
      setButton('');
    }
  };

  useEffect(() => {
    setMenuClass();
    window.addEventListener('resize', setMenuClass);
  }, []);

  return (
    <>
      <header className={`header show-header ${claseMenu} ${clickMenu ? 'active' : ''}`}>
        <div className="menu-outer">
          <div className="menu-inner">
            <div className='header-logo-container'>
              <h1 className="logo">
                <Link
                  to='/'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Franco Canepa
                </Link>
              </h1>
              <button className={`hamburger mobile-menu-trigger ${clickMenu ? 'is-active' : ''}`} type="button" aria-label="Menu" onClick={handleClick}>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
            <div className="header-items-container">
              <nav className="menu">
                <ul className="menu-links">
                  <li className="item  show-link">
                    <Link
                      to='/'
                      className='nav-links'
                      onClick={closeMobileMenu}
                    >
                      Inicio
                    </Link>
                  </li>
                  <li className="item  show-link">
                    <Link
                      to='/contact'
                      className='nav-links'
                      onClick={closeMobileMenu}
                    >
                      Contacto
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default MenuBar
